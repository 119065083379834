<template>
<div>
    <Processing v-if="isLoading" />
    <div v-else class="lesson-view">
        <form @submit.prevent="editDailyGuide">
            <div class="form-group">
                <label for="">Date</label>
                <div>{{ lesson.date }}</div>
            </div>
            <div class="form-group">
                <label for="">Title</label>
                <input type="text" class="form-control" required v-model="lesson.title">
            </div>
            <div class="form-group">
                <label for="">Reference Text</label>
                <input type="text" class="form-control" required v-model="lesson.reference">
            </div>
            <div class="form-group">
                <label for="">Content</label>
                <TextareaAutosize
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.content"
                    :min-height="30"
                    :max-height="350" />
            </div>
            <div class="form-group">
                <label for="">Prayer and Confession</label>
                <TextareaAutosize
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.action"
                    :min-height="30"
                    :max-height="350" />
            </div>





            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isThought" :checked="lesson.isThought">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">Thoughts</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isThought"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.thought"
                    :min-height="30"
                    :max-height="350" />
            </div>
            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isSoWhat">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">So What?</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isSoWhat"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.sowhat"
                    :min-height="30"
                    :max-height="350" />
            </div>
            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isQuestion">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">Question</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isQuestion"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.question"
                    :min-height="30"
                    :max-height="350" />
            </div>

            
            <button class="btn btn-success" :disabled="isProcessing">
                <span>Edit Lesson</span>
                <loader v-if="isProcessing" />
            </button>
        </form>

        <!-- <pre>{{ lesson }}</pre> -->

    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            lesson: {
                title: "",
                reference: "",
                content: "",
                action: "",
                isThought: false,
                isPrayer: false,
                isSoWhat: false,
                isQuestion: false,
                thought: "",
                question: "",
                prayer: "",
                sowhat: "",
            },

            isProcessing: false,
            isLoading: false,
        }
    },
    created(){
        this.getLesson()
    },
    methods: {

        async getLesson(){
            this.isLoading = true
            const month = this.$route.params.month
            const lessonId = this.$route.params.lessonId
            const year = this.$store.state.devotionalYear
            const doc = await this.$db
                .collection("devotionals")
                .doc("dailyguide")
                .collection("year")
                .doc(year)
                .collection(month)
                .doc(lessonId)
                .get()
            
            this.lesson = doc.data()
            this.isLoading = false
        },

        async editDailyGuide(){
            this.isProcessing = true
            const editDailyGuide = this.$fn.httpsCallable("editDailyGuide")
            await editDailyGuide(this.lesson)
            this.$toast.success("Lesson editted successfully")
            this.isProcessing = false
        },

    },
    components:{
        "Processing": () => import("@/components/Loaders/Processing.vue"),
        "loader": () => import("@/components/Loaders/Button.vue"),
    }
}
</script>